import { Fragment, FunctionalComponent, h } from 'preact';
import { Route, Router } from 'preact-router';

import NotFoundPage from '../routes/notfound';
import Header from './header';
import Clients from '../routes/clients';
import Accounts from '../routes/accounts';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearTokens, getAuthorization, getTokenGrants, initializeTokenManager } from '../services/token-service';
import Login from '../routes/login';
import Dashboard from '../routes/dashboard';
import { useState } from 'preact/hooks';
import Roles from '../routes/roles';

const App: FunctionalComponent = () => {
    const [grants, setGrants] = useState<string[] | undefined>(getTokenGrants());

    initializeTokenManager(setGrants);

    const logout = () => {
        clearTokens();
        setGrants(undefined);
    };

    const login = async (email?: string, password?: string) => {
        const emailRegex =
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        if (!email) {
            toast("Email is required", { type: "error" });
            return;
        }
        email = email.trim();
        if (!emailRegex.test(email)) {
            toast("Email is invalid", { type: "error" });
            return;
        }

        if (!password) {
            toast("Password is required", { type: "error" });
            return;
        }
        if (password.length < 6) {
            toast("Password must be at least 6 characters", { type: "error" });
            return;
        }

        if (!(await getAuthorization(email, password))) {
            toast("Login failed", { type: "error" });
            return;
        }

        setGrants(getTokenGrants());
    };

    return (
        <div id="preact_root" class='bg-gray-800'>
            { grants !== undefined
            ? 
            <>
                <Header logout={logout}/>
                <Router>
                    <Route path="/" component={Dashboard} />
                    <Route path="/clients/" component={Clients} />
                    <Route path="/accounts/" component={Accounts} />
                    <Route path="/roles/" component={Roles} />
                    <NotFoundPage default />
                </Router>
            </>
            : <Login login={login}/>
            }
            
            <ToastContainer
                position='top-right'
                autoClose={4000}
                pauseOnHover
                theme='dark'
            />
        </div>
    );
};

export default App;
