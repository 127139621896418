export let BACKEND_URL: string;

console.log("Environment:", process.env.NODE_ENV)

if (process.env.NODE_ENV !== "development") {
    BACKEND_URL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/api`;
}
else {
    BACKEND_URL = "http://localhost:3000/v1";
}
